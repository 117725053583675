import { AxiosError } from "axios"
import { useState } from "react"
import { useNavigate } from "react-router-dom"

import { ApiConnection } from "../../api/api-client/common/ApiConnection"
import { login, setPasswordForUser } from "../../api/lib/auth/auth"
import { getUser } from "../../api/lib/user/user"
import {
  ProbateCasePageContentContainer,
  ProbateCasePageContentContainerVariant,
} from "../../component/layouts/ProbateCasePageContentContainer/ProbateCasePageContentContainer"
import AlertMessage, {
  MessageType,
} from "../../component/modules/AlertMessage/AlertMessage"
import { AlertMessageItem } from "../../component/modules/AlertMessage/AlertMessageItemList/AlertMessageItemList"
import ConfirmIdentity, {
  ConfirmIdentityErrorMessages,
} from "../../component/organism/ConfirmIdentity/ConfirmIdentity"
import { LoadingFallBack } from "../../component/organism/LoadingFallBack"
import { RegisterOrResetPassword } from "../../component/organism/RegisterOrResetPassword/RegisterOrResetPassword"
import {
  PAGE_PATH,
  PasswordSetupSteps,
} from "../../contexts/application/constants"
import { useApplicationContext } from "../../contexts/application/context"
import { useAuthContext } from "../../contexts/auth/authContextProvider"
import { useThumbnailContext } from "../../contexts/thumbnails"
import { useToastContext } from "../../contexts/toasts"
import { useUserContext } from "../../contexts/users"
import { connectToApiAndReturnUser } from "../../utils/auth/lib/connectToApiAndReturnUser"
import { disconnect } from "../../utils/auth/login"
import { useRegistrationDetails } from "../../utils/hooks/useRegistrationDetails"
import { redirectAfterLoggedInBasedOnSharedWithMeFeature } from "../../utils/routing/redirectAfterLoggedInBasedOnSharedWithMeFeature"
import { useCodeFromRoute } from "./utils/useCodeFromRoute"

// At the moment this page always expect that the user is using 2FA,
// Would possible to skip this in the future but for now, it's always required.
// Basically if response.smsCodeRequired from "SetPasswordResponse" is true then we should show the 2FA page otherwise we should skip it and login the user.
const RegisterPage = () => {
  const {
    dispatch,
    applicationState: { isLoading },
  } = useApplicationContext()
  const { dispatch: dispatchUser } = useUserContext()
  const { dispatch: thumbnailDispatch } = useThumbnailContext()
  const { dispatch: toastDispatch } = useToastContext()

  const { setUserInfo, clearLocalStorage } = useAuthContext()

  const [newPassword, setNewPassword] = useState("")
  const [additionalAssistanceText, setAdditionalAssistanceText] = useState<
    string | undefined
  >(undefined)
  const [registrationStep, setRegistrationStep] = useState<PasswordSetupSteps>(
    PasswordSetupSteps.SetupPassword
  )
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [validationError, setValidationError] = useState<
    AlertMessageItem[] | undefined
  >(undefined)
  const [
    hasUnexpectedErrorConfirmIdentity,
    setHasUnexpectedErrorConfirmIdentity,
  ] = useState(false)
  const [hasUserLockedOutError, setHasUserLockedOutError] = useState(false)

  const activationCode = useCodeFromRoute("key") ?? ""
  const navigate = useNavigate()

  if (!activationCode) {
    navigate(PAGE_PATH.LoginPage)
  }
  const { email, lastFourPhoneDigits, shouldShowContactUsError } =
    useRegistrationDetails({
      activationCode: activationCode ?? "",
      dispatch: dispatch,
    })

  const handleOnRegister = ({
    password,
    additionalAssistanceText,
  }: {
    password: string
    additionalAssistanceText: string
  }) => {
    setNewPassword(password)
    setAdditionalAssistanceText(additionalAssistanceText)
    setRegistrationStep(PasswordSetupSteps.ConfirmIdentity)
  }

  const onConfirmIdentity = async (twoFactorCode: string) => {
    let isPasswordSetSuccess = false
    setIsSubmitting(true)
    try {
      const { failed } = await setPasswordForUser({
        newPassword,
        activationCode,
        twoFactorCode,
        additionalAssistanceText,
      })
      isPasswordSetSuccess = failed.length === 0
    } catch (error) {
      if ((error as AxiosError).response?.status === 401) {
        setValidationError([
          {
            id: 2,
            message: ConfirmIdentityErrorMessages.INVALID,
            href: "confirm-identity",
          },
        ])
      } else if (
        (error as AxiosError).response?.status === 400 &&
        (error as AxiosError).response?.data === "User has been Locked out"
      ) {
        setHasUserLockedOutError(true)
      } else {
        setHasUnexpectedErrorConfirmIdentity(true)
      }
    }

    // After setting the password, login with new password
    if (isPasswordSetSuccess) {
      try {
        clearLocalStorage()
        disconnect({ apiConnection: undefined, dispatch })
        const response = await login({
          email,
          password: newPassword,
          code: twoFactorCode,
        })
        setIsSubmitting(false)
        setUserInfo({
          token: response.token,
          apiKey: response.apiKey,
          userName: email,
        })

        //backwards compatibility for the old api controller calls
        const apiConnection = new ApiConnection(response.apiKey, email)
        await connectToApiAndReturnUser({
          apiConnection,
          token: response.token,
          dispatch,
          dispatchUser,
          thumbnailDispatch,
          toastDispatch,
          shouldAutoRefreshToken: false,
        })

        const user = await getUser({ email })
        redirectAfterLoggedInBasedOnSharedWithMeFeature({
          user,
          navigate,
        })
      } catch (error) {
        if ((error as AxiosError).response?.status === 500) {
          setHasUnexpectedErrorConfirmIdentity(true)
        }
      }
    }
    setIsSubmitting(false)
  }

  const onSendSmsCodeAgain = async () => {
    try {
      setHasUnexpectedErrorConfirmIdentity(false)
      await setPasswordForUser({
        newPassword,
        activationCode,
      })
    } catch (error) {
      if (
        (error as AxiosError).response?.status === 400 &&
        (error as AxiosError).response?.data === "User has been Locked out"
      ) {
        setHasUserLockedOutError(true)
      } else {
        setHasUnexpectedErrorConfirmIdentity(true)
      }
    }
  }

  if (isLoading) {
    return <LoadingFallBack />
  }

  return (
    <ProbateCasePageContentContainer
      pageVariant={ProbateCasePageContentContainerVariant.WelcomePage}
    >
      <div className="register-page">
        <div className="register-page__right-content">
          {registrationStep === PasswordSetupSteps.SetupPassword &&
            shouldShowContactUsError === false && (
              <RegisterOrResetPassword
                email={email ?? ""}
                onRegister={handleOnRegister}
                activationCode={activationCode ?? ""}
                passwordSetupStep={PasswordSetupSteps.SetupPassword}
              />
            )}
          {registrationStep === PasswordSetupSteps.SetupPassword &&
            shouldShowContactUsError && (
              <div className="mb-30">
                <AlertMessage
                  title="There's a problem"
                  messageType={MessageType.ERROR}
                  message="This email invitation is no longer available. Please call us on 0330 606 9423 or by contacting your case manager directly."
                />
              </div>
            )}
          {registrationStep === PasswordSetupSteps.ConfirmIdentity && (
            <ConfirmIdentity
              lastFourPhoneDigits={lastFourPhoneDigits}
              onBackClick={() => {
                setRegistrationStep(PasswordSetupSteps.SetupPassword)
              }}
              isSubmitting={isSubmitting}
              hasUnexpectedError={hasUnexpectedErrorConfirmIdentity}
              hasUserLockedOutError={hasUserLockedOutError}
              validationError={validationError}
              setValidationError={setValidationError}
              onConfirmIdentity={onConfirmIdentity}
              onSendSmsCodeAgain={onSendSmsCodeAgain}
            />
          )}
        </div>
      </div>
    </ProbateCasePageContentContainer>
  )
}

export default RegisterPage
