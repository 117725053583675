import { AxiosError } from "axios"
import classNames from "classnames"
import { useEffect, useState } from "react"
import { useNavigate, useSearchParams } from "react-router-dom"

import { ApiConnection } from "../../api/api-client/common/ApiConnection"
import { login } from "../../api/lib/auth/auth"
import { getUser } from "../../api/lib/user/user"
import {
  ProbateCasePageContentContainer,
  ProbateCasePageContentContainerVariant,
} from "../../component/layouts/ProbateCasePageContentContainer/ProbateCasePageContentContainer"
import { LeftNavigationBar } from "../../component/LeftNavigationBar/LeftNavigationBar"
import { CoopWelcomeText } from "../../component/LeftNavigationBar/LogoWithTextItems/coopWelcomeText"
import {
  LogoWithTextItems,
  WelcomeTextComponent,
} from "../../component/LeftNavigationBar/LogoWithTextItems/LogoWithTextItems"
import { AlertMessageItem } from "../../component/modules/AlertMessage/AlertMessageItemList/AlertMessageItemList"
import ConfirmIdentity, {
  ConfirmIdentityErrorMessages,
} from "../../component/organism/ConfirmIdentity/ConfirmIdentity"
import ForgotPassword from "../../component/organism/ForgotPassword/ForgotPassword"
import { LoadingFallBack } from "../../component/organism/LoadingFallBack"
import SignInPanel from "../../component/organism/SignInPanel/SignInPanel"
import { setShowFooter, setShowMenu } from "../../contexts/application/action"
import { PAGE_PATH, UserModuleType } from "../../contexts/application/constants"
import { useApplicationContext } from "../../contexts/application/context"
import { useAuthContext } from "../../contexts/auth/authContextProvider"
import { useThumbnailContext } from "../../contexts/thumbnails"
import { useToastContext } from "../../contexts/toasts"
import { useUserContext } from "../../contexts/users"
import CoopLogo from "../../design-tokens/imgs/coop/CoopLogo"
import { useLeftNavMenu } from "../../hooks/useLeftNavMenu"
import { connectToApiAndReturnUser } from "../../utils/auth/lib/connectToApiAndReturnUser"
import { disconnect } from "../../utils/auth/login"
import { redirectAfterLoggedInBasedOnSharedWithMeFeature } from "../../utils/routing/redirectAfterLoggedInBasedOnSharedWithMeFeature"

import "./LoginPage.css"

export enum LoginSteps {
  SignIn = "signIn",
  ConfirmIdentity = "confirmIdentity",
  ForgottenPassword = "forgottenPassword",
}

const LoginPage = () => {
  const {
    dispatch,
    applicationState: { authLoading },
  } = useApplicationContext()
  const {
    dispatch: dispatchUser,
    userState: { currentUser },
  } = useUserContext()
  const { dispatch: thumbnailDispatch } = useThumbnailContext()
  const { dispatch: toastDispatch } = useToastContext()
  const { setUserInfo, logout } = useAuthContext()

  const [searchParams] = useSearchParams()
  const isForgottenPasswordStartedFromProfilePage = searchParams.get(
    "isStartedFromProfile"
  )

  const { isLeftNavShowing, setIsLeftNavShowing, isDesktop } = useLeftNavMenu()
  const [loginStep, setLoginStep] = useState<LoginSteps>(
    isForgottenPasswordStartedFromProfilePage
      ? LoginSteps.ForgottenPassword
      : LoginSteps.SignIn
  )
  const [lastFourPhoneDigits, setLastFourPhoneDigits] = useState("")
  const [password, setPassword] = useState("")
  const [email, setEmail] = useState("")
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [serverErrorMessage, setServerErrorMessage] = useState("")
  const [validationError, setValidationError] = useState<
    AlertMessageItem[] | undefined
  >(undefined)
  const [
    hasUnexpectedErrorConfirmIdentity,
    setHasUnexpectedErrorConfirmIdentity,
  ] = useState(false)
  const [hasUserLockedOutError, setHasUserLockedOutError] = useState(false)

  const navigate = useNavigate()

  useEffect(() => {
    // If the user is already logged in via SSO and have the correct modules, redirect them to the correct page - COOP case handler case scenario.
    if (
      currentUser?.modules?.includes(
        UserModuleType.CASE_MANAGER_DOCUMENT_EXCHANGE
      )
    ) {
      navigate(PAGE_PATH.CaseManagerHomePage)
    }
    dispatch(setShowMenu(false))
    dispatch(setShowFooter(false))
  }, [currentUser?.modules, dispatch, navigate])

  // This is used for the sign in step
  const onSignIn = async ({
    email,
    password,
  }: {
    email: string
    password: string
  }) => {
    try {
      setIsSubmitting(true)
      setHasUserLockedOutError(false)
      logout()
      disconnect({ apiConnection: undefined, dispatch })

      const response = await login({ email, password })

      //if 2fa is disabled for this user, the token will be returned directly
      if (response.token) {
        setUserInfo({
          token: response.token,
          apiKey: response.apiKey,
          userName: email,
        })

        //backwards compatibility for the old api controller calls
        const apiConnection = new ApiConnection(response.apiKey, email)
        await connectToApiAndReturnUser({
          apiConnection,
          token: response.token,
          dispatch,
          dispatchUser,
          thumbnailDispatch,
          toastDispatch,
          shouldAutoRefreshToken: false,
        })

        const user = await getUser({ email })

        redirectAfterLoggedInBasedOnSharedWithMeFeature({
          user,
          navigate,
        })
      }
      //otherwise, proceed to 2fa
      else {
        setLastFourPhoneDigits(response.usersPhoneNumberDigits)
        setEmail(email)
        setPassword(password)
        setLoginStep(LoginSteps.ConfirmIdentity)
      }
    } catch (error) {
      if (
        (error as AxiosError).response?.status === 400 &&
        (error as AxiosError).response?.data === "User has been Locked out"
      ) {
        setServerErrorMessage(
          "For security reasons you have been locked out after too many failed attempts. Please try again in 30 minutes."
        )
      } else {
        setServerErrorMessage(
          "Your email or password aren’t correct. Check you’ve typed them correctly and try again."
        )
      }
    } finally {
      setIsSubmitting(false)
    }
  }

  // Used for the confirm identity step
  const onConfirmIdentity = async (twoFactorCode: string) => {
    try {
      setHasUnexpectedErrorConfirmIdentity(false)
      setHasUserLockedOutError(false)
      setIsSubmitting(true)
      logout()
      disconnect({ apiConnection: undefined, dispatch })

      const response = await login({
        email,
        password,
        code: twoFactorCode,
      })
      setIsSubmitting(false)
      setUserInfo({
        token: response.token,
        apiKey: response.apiKey,
        userName: email,
      })

      //backwards compatibility for the old api controller calls
      const apiConnection = new ApiConnection(response.apiKey, email)
      await connectToApiAndReturnUser({
        apiConnection,
        token: response.token,
        dispatch,
        dispatchUser,
        thumbnailDispatch,
        toastDispatch,
        shouldAutoRefreshToken: false,
      })

      const user = await getUser({ email })

      redirectAfterLoggedInBasedOnSharedWithMeFeature({
        user,
        navigate,
      })
    } catch (error) {
      if ((error as AxiosError).response?.status === 401) {
        setValidationError([
          {
            id: 2,
            message: ConfirmIdentityErrorMessages.INVALID,
            href: "confirm-identity",
          },
        ])
      } else if (
        (error as AxiosError).response?.status === 400 &&
        (error as AxiosError).response?.data === "User has been Locked out"
      ) {
        setHasUserLockedOutError(true)
      } else {
        setHasUnexpectedErrorConfirmIdentity(true)
      }
    } finally {
      setIsSubmitting(false)
    }
  }

  const onSendSmsCodeAgain = async () => {
    try {
      setHasUnexpectedErrorConfirmIdentity(false)
      await login({ email, password })
    } catch (error) {
      if (
        (error as AxiosError).response?.status === 400 &&
        (error as AxiosError).response?.data === "User has been Locked out"
      ) {
        setHasUserLockedOutError(true)
      } else {
        setHasUnexpectedErrorConfirmIdentity(true)
      }
    }
  }

  const rightContentWithBackgroundClassName = classNames(
    "login-page__right-content",
    {
      "background--sign-in": loginStep === LoginSteps.SignIn,
      "background--confirm-identity": loginStep === LoginSteps.ConfirmIdentity,
    }
  )

  // This was added as a workaround to fix the issue with flickering when starting or refreshing the application.
  if (
    authLoading ||
    currentUser?.modules?.includes(
      UserModuleType.CASE_MANAGER_DOCUMENT_EXCHANGE
    )
  ) {
    return <LoadingFallBack />
  }

  return (
    <>
      {loginStep === LoginSteps.SignIn && (
        <div className="login-step__container">
          {isDesktop && (
            <LeftNavigationBar
              className="expanded"
              isDesktop={isDesktop}
              setIsMenuBarExpanded={setIsLeftNavShowing}
              isMenuBarExpanded={isLeftNavShowing}
            >
              <LogoWithTextItems
                logo={<CoopLogo />}
                headerText="Welcome"
                contentText={CoopWelcomeText}
              />
            </LeftNavigationBar>
          )}
          <div className="login-page__right-content-sign-in-panel">
            <SignInPanel
              onSignIn={onSignIn}
              serverErrorMessage={serverErrorMessage}
              isSubmitting={isSubmitting}
              setServerErrorMessage={setServerErrorMessage}
              setLoginStep={setLoginStep}
            />
          </div>
          {!isDesktop && (
            <WelcomeTextComponent
              className={"login-page__right-content-welcome-text"}
            />
          )}
        </div>
      )}
      {loginStep === LoginSteps.ForgottenPassword && (
        <div className="login-step__container">
          {isDesktop && (
            <LeftNavigationBar
              className="expanded"
              isDesktop={isDesktop}
              setIsMenuBarExpanded={setIsLeftNavShowing}
              isMenuBarExpanded={isLeftNavShowing}
            >
              <LogoWithTextItems
                logo={<CoopLogo />}
                headerText="Welcome"
                contentText={CoopWelcomeText}
              />
            </LeftNavigationBar>
          )}
          <div className="login-page__right-content-sign-in-panel">
            <ForgotPassword
              setLoginStep={setLoginStep}
              labelTitle={
                isForgottenPasswordStartedFromProfilePage
                  ? "Change your password"
                  : "Forgotten your password"
              }
            />
          </div>
          {!isDesktop && (
            <WelcomeTextComponent
              className={"login-page__right-content-welcome-text"}
            />
          )}
        </div>
      )}
      {loginStep === LoginSteps.ConfirmIdentity && (
        <ProbateCasePageContentContainer
          className={rightContentWithBackgroundClassName}
          pageVariant={ProbateCasePageContentContainerVariant.WelcomePage}
        >
          <div className="login-page">
            <ConfirmIdentity
              lastFourPhoneDigits={lastFourPhoneDigits}
              onBackClick={() => {
                setLoginStep(LoginSteps.SignIn)
              }}
              validationError={validationError}
              setValidationError={setValidationError}
              onConfirmIdentity={onConfirmIdentity}
              isSubmitting={isSubmitting}
              hasUnexpectedError={hasUnexpectedErrorConfirmIdentity}
              hasUserLockedOutError={hasUserLockedOutError}
              onSendSmsCodeAgain={onSendSmsCodeAgain}
            />
          </div>
        </ProbateCasePageContentContainer>
      )}
    </>
  )
}

export default LoginPage
