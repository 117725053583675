import { AxiosError } from "axios"
import { useState } from "react"
import { useNavigate } from "react-router-dom"

import { ApiConnection } from "../../api/api-client/common/ApiConnection"
import { login, resetPassword } from "../../api/lib/auth/auth"
import { getUser } from "../../api/lib/user/user"
import { BUTTON_VARIANT, Button } from "../../component/atoms/Button"
import { LoadingSpinner } from "../../component/atoms/LoadingSpinner/LoadingSpinner"
import {
  ProbateCasePageContentContainer,
  ProbateCasePageContentContainerVariant,
} from "../../component/layouts/ProbateCasePageContentContainer/ProbateCasePageContentContainer"
import { AlertMessageItem } from "../../component/modules/AlertMessage/AlertMessageItemList/AlertMessageItemList"
import ConfirmIdentity, {
  ConfirmIdentityErrorMessages,
} from "../../component/organism/ConfirmIdentity/ConfirmIdentity"
import { LoadingFallBack } from "../../component/organism/LoadingFallBack"
import { RegisterOrResetPassword } from "../../component/organism/RegisterOrResetPassword/RegisterOrResetPassword"
import { PasswordSetupSteps } from "../../contexts/application/constants"
import { useApplicationContext } from "../../contexts/application/context"
import { useAuthContext } from "../../contexts/auth/authContextProvider"
import { useThumbnailContext } from "../../contexts/thumbnails"
import { useToastContext } from "../../contexts/toasts"
import { useUserContext } from "../../contexts/users"
import { connectToApiAndReturnUser } from "../../utils/auth/lib/connectToApiAndReturnUser"
import { disconnect } from "../../utils/auth/login"
import { useRegistrationDetails } from "../../utils/hooks/useRegistrationDetails"
import { redirectAfterLoggedInBasedOnSharedWithMeFeature } from "../../utils/routing/redirectAfterLoggedInBasedOnSharedWithMeFeature"
import { useCodeFromRoute } from "../RegisterPage/utils/useCodeFromRoute"

import "./ResetPasswordPage.css"

const ResetPasswordPage = () => {
  const {
    dispatch,
    applicationState: { isLoading },
  } = useApplicationContext()
  const { setUserInfo, clearLocalStorage } = useAuthContext()
  const { dispatch: dispatchUser } = useUserContext()
  const { dispatch: thumbnailDispatch } = useThumbnailContext()
  const { dispatch: toastDispatch } = useToastContext()

  const [newPassword, setNewPassword] = useState("")
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [
    hasUnexpectedErrorConfirmIdentity,
    setHasUnexpectedErrorConfirmIdentity,
  ] = useState(false)
  const [twoFactorCode, setTwoFactorCode] = useState("")

  const [resetPasswordStep, setResetPasswordStep] =
    useState<PasswordSetupSteps>(PasswordSetupSteps.ResetPassword)
  const [validationError, setValidationError] = useState<
    AlertMessageItem[] | undefined
  >(undefined)
  const [hasUserLockedOutError, setHasUserLockedOutError] = useState(false)

  const activationCode = useCodeFromRoute("actCode")

  const { email, lastFourPhoneDigits } = useRegistrationDetails({
    activationCode: activationCode ?? "",
    dispatch: dispatch,
  })

  const handleOnReset = ({ password }: { password: string }) => {
    setNewPassword(password)
    setResetPasswordStep(PasswordSetupSteps.ConfirmIdentity)
  }

  const onConfirmIdentity = async (twoFactorCode: string) => {
    let isPasswordSetSuccess = false
    setIsSubmitting(true)
    try {
      const response = await resetPassword({
        newPassword,
        activationCode: activationCode ?? "",
        twoFactorCode,
      })
      isPasswordSetSuccess = response.failed.length === 0
    } catch (error) {
      if ((error as AxiosError).response?.status === 401) {
        setValidationError([
          {
            id: 2,
            message: ConfirmIdentityErrorMessages.INVALID,
            href: "confirm-identity",
          },
        ])
      } else if (
        (error as AxiosError).response?.status === 400 &&
        (error as AxiosError).response?.data === "User has been Locked out"
      ) {
        setHasUserLockedOutError(true)
      } else {
        setHasUnexpectedErrorConfirmIdentity(true)
      }
    }

    // After setting the password, login with new password with via continue step
    if (isPasswordSetSuccess) {
      setTwoFactorCode(twoFactorCode)
      setResetPasswordStep(PasswordSetupSteps.ResetPasswordConfirmation)
    }
    setIsSubmitting(false)
  }

  const onSendSmsCodeAgain = async () => {
    try {
      setHasUnexpectedErrorConfirmIdentity(false)
      await resetPassword({
        newPassword,
        activationCode: activationCode ?? "",
      })
    } catch (error) {
      if (
        (error as AxiosError).response?.status === 400 &&
        (error as AxiosError).response?.data === "User has been Locked out"
      ) {
        setHasUserLockedOutError(true)
      } else {
        setHasUnexpectedErrorConfirmIdentity(true)
      }
    }
  }

  if (isLoading && isSubmitting) {
    return <LoadingFallBack />
  }

  const ResetPasswordConfirmation = ({
    twoFactorCode,
  }: {
    twoFactorCode: string
  }) => {
    const navigate = useNavigate()

    const handleContinueClick = async () => {
      setIsSubmitting(true)
      try {
        clearLocalStorage()
        disconnect({ apiConnection: undefined, dispatch })
        const response = await login({
          email,
          password: newPassword,
          code: twoFactorCode,
        })
        setUserInfo({
          token: response.token,
          apiKey: response.apiKey,
          userName: email,
        })

        //backwards compatibility for the old api controller calls
        const apiConnection = new ApiConnection(response.apiKey, email)
        await connectToApiAndReturnUser({
          apiConnection,
          token: response.token,
          dispatch,
          dispatchUser,
          thumbnailDispatch,
          toastDispatch,
          shouldAutoRefreshToken: false,
        })
        const user = await getUser({ email })
        redirectAfterLoggedInBasedOnSharedWithMeFeature({
          user,
          navigate,
        })
      } catch (error) {
        if ((error as AxiosError).response?.status === 500) {
          setHasUnexpectedErrorConfirmIdentity(true)
        } else if (
          (error as AxiosError).response?.status === 400 &&
          (error as AxiosError).response?.data === "User has been Locked out"
        ) {
          setHasUserLockedOutError(true)
        }
      } finally {
        setIsSubmitting(false)
      }
    }

    return (
      <>
        <div className="reset-password-page_confirmation">
          <div>
            <h1>Reset password confirmation</h1>
            <p className="reset-password-page-confirmation__success-message">
              Your password has been reset successfully.
            </p>
            <p className="reset-password-page-confirmation__continue">
              Please click the button below to continue.
            </p>
            <Button
              variant={BUTTON_VARIANT.PRIMARY}
              onClick={handleContinueClick}
              isDisabled={isSubmitting}
            >
              {!isSubmitting && "Continue"}
              {isSubmitting && (
                <div style={{ display: "flex", alignItems: "center" }}>
                  <span className="mr-1">Signing in ...</span>
                  <LoadingSpinner
                    size="20px"
                    thickness="2px"
                    color="var(--color-universal-secondary-e)"
                  />
                </div>
              )}
            </Button>
          </div>
        </div>
      </>
    )
  }

  return (
    <ProbateCasePageContentContainer
      pageVariant={ProbateCasePageContentContainerVariant.WelcomePage}
    >
      <div className="reset-password-page">
        <div className="reset-password-page__right-content">
          {resetPasswordStep === PasswordSetupSteps.ResetPassword && (
            <RegisterOrResetPassword
              passwordSetupStep={PasswordSetupSteps.ResetPassword}
              onReset={handleOnReset}
              activationCode={activationCode ?? ""}
            />
          )}
          {resetPasswordStep === PasswordSetupSteps.ConfirmIdentity && (
            <ConfirmIdentity
              lastFourPhoneDigits={lastFourPhoneDigits}
              onBackClick={() => {
                setResetPasswordStep(PasswordSetupSteps.ResetPassword)
              }}
              isSubmitting={isSubmitting}
              hasUnexpectedError={hasUnexpectedErrorConfirmIdentity}
              hasUserLockedOutError={hasUserLockedOutError}
              validationError={validationError}
              setValidationError={setValidationError}
              onConfirmIdentity={onConfirmIdentity}
              onSendSmsCodeAgain={onSendSmsCodeAgain}
              resetPasswordStep={PasswordSetupSteps.ResetPassword}
            />
          )}

          {resetPasswordStep ===
            PasswordSetupSteps.ResetPasswordConfirmation && (
            <ResetPasswordConfirmation twoFactorCode={twoFactorCode} />
          )}
        </div>
      </div>
    </ProbateCasePageContentContainer>
  )
}

export default ResetPasswordPage
